import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import PageBuilder from "../components/page-builder";

export const query = graphql`
  query PagesTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      title
      id
      pageSEO {
        metaDescription
        metaTitle
        metaImage {
          asset {
            _id
            altText
          }
          hotspot {
            height
            width
            x
            y
          }
        }
      }
      parentPage {
        slug {
          current
        }
        title
        id
        parentPage {
          id
          slug {
            current
          }
          title
        }
      }
      _rawPageBuilder(resolveReferences: { maxDepth: 8 })
      pageBuilder {
        ... on SanityAccordionModule {
          _key
          _type
          heading
          items {
            heading
            _rawBody(resolveReferences: { maxDepth: 8 })
          }
          buttonText
          ...accordionModuleButtonLink
        }
        ... on SanitySponsorModule {
          _type
          subheading
          heading
          items {
            type
            items {
              title
              icon {
                asset {
                  _id
                  altText
                }
              }
              externalLink
            }
          }
        }
        ... on SanityAvatarModule {
          _key
          _type
          heading
          items {
            description
            email
            location
            fullName
            number
            role
            image {
              asset {
                altText
                _id
              }
            }
            linkAddress {
              landingPageRoute {
                ... on SanityPage {
                  id
                  slug {
                    current
                  }
                  path
                }
                ... on SanityNewsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityNews {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEventsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityContactPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEvents {
                  id
                  _type
                  slug {
                    current
                  }
                }
              }
              route
              link
              pdf {
                asset {
                  url
                }
              }
            }
            linkAddress {
              landingPageRoute {
                ... on SanityPage {
                  id
                  slug {
                    current
                  }
                  path
                }
                ... on SanityNewsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityNews {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEventsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityContactPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEvents {
                  id
                  _type
                  slug {
                    current
                  }
                }
              }
              link
              route
              pdf {
                asset {
                  url
                }
              }
            }
          }
        }
        ... on SanityDecisionModule {
          _type
          items {
            heading
            buttonText
            buttonLink {
              landingPageRoute {
                ... on SanityPage {
                  id
                  slug {
                    current
                  }
                  path
                }
                ... on SanityNewsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityNews {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEventsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityContactPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEvents {
                  id
                  _type
                  slug {
                    current
                  }
                }
              }
              link
              route
              pdf {
                asset {
                  url
                }
              }
            }
            _rawBody(resolveReferences: { maxDepth: 8 })
          }
        }
        ... on SanityContentTileModule {
          _key
          _type
          columns
          heading
          items {
            ... on SanityEvents {
              id
              _type
              title
              details {
                costHeading
                costDescription
                costValue
              }
              tags {
                value
              }
              slug {
                current
              }
              tileDescription
              path
              startDate
              endDate
              image {
                asset {
                  _id
                  altText
                }
              }
            }
            ... on SanityNews {
              id
              _type
              title
              tags {
                value
              }
              slug {
                current
              }
              tileDescription
              path
              image {
                image {
                  asset {
                    _id
                    altText
                  }
                }
              }
              publishedDate
            }
          }
        }
        ... on SanityHeroBanner {
          _key
          _type
          title
          intro
          buttonText
          ...heroBannerButtonLink
        }
        ... on SanityMembershipDetailModule {
          _key
          _type
          heading
          intro
          items {
            buttonText
            buttonLink {
              landingPageRoute {
                ... on SanityPage {
                  id
                  slug {
                    current
                  }
                  path
                }
                ... on SanityNewsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityNews {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEventsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityContactPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEvents {
                  id
                  _type
                  slug {
                    current
                  }
                }
              }
              link
              route
              pdf {
                asset {
                  url
                }
              }
            }
            details {
              heading
              value
            }
            heading
            _rawBody(resolveReferences: { maxDepth: 8 })
          }
        }
        ... on SanitySpeakerModule {
          _key
          _type
          heading
          items {
            description
            email
            location
            fullName
            number
            role
            image {
              asset {
                altText
                _id
              }
            }
            linkAddress {
              landingPageRoute {
                ... on SanityPage {
                  id
                  slug {
                    current
                  }
                  path
                }
                ... on SanityNewsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityNews {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEventsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityContactPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEvents {
                  id
                  _type
                  slug {
                    current
                  }
                }
              }
              route
              link
              pdf {
                asset {
                  url
                }
              }
            }
            linkAddress {
              landingPageRoute {
                ... on SanityPage {
                  id
                  slug {
                    current
                  }
                  path
                }
                ... on SanityNewsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityNews {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEventsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityContactPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEvents {
                  id
                  _type
                  slug {
                    current
                  }
                }
              }
              link
              route
              pdf {
                asset {
                  url
                }
              }
            }
          }
        }
        ... on SanityExpelledMembersModule {
          include
          _type
        }
        ... on SanityMentorsModule {
          include
          _type
        }
        ... on SanityEsteemedMembersModule {
          include
          _type
        }
        ... on SanityContentBlockArray {
          _type
          heading
          block {
            _rawBlock(resolveReferences: { maxDepth: 8 })
          }
        }
        ... on SanityStepsModule {
          _type
          heading
          items {
            heading
            buttonText
            buttonLink {
              landingPageRoute {
                ... on SanityPage {
                  id
                  slug {
                    current
                  }
                  path
                }
                ... on SanityNewsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityNews {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEventsPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityContactPage {
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityEvents {
                  id
                  _type
                  slug {
                    current
                  }
                }
              }
              link
              route
              pdf {
                asset {
                  url
                }
              }
            }
            image {
              asset {
                _id
                altText
              }
            }
            _rawBody(resolveReferences: { maxDepth: 8 })
          }
        }
        ... on SanitySmallBanner {
          _key
          _type
          ...smallBannerButtonLink
          type
          buttonText
          intro
          alignment
          title
          image {
            asset {
              _id
              altText
            }
          }
        }
      }
    }
  }
`;

const PagesTemplate = props => {
  const { data } = props;
  const page = data.page;
  let currentpage = {
    primary: {},
    secondary: {},
    tertiary: {},
  };

  if (page.parentPage) {
    currentpage.primary.id = page.parentPage.id;
    currentpage.primary.title = page.parentPage.title;
    currentpage.secondary.id = page.id;
    currentpage.secondary.title = page.title;
    if (page.parentPage.parentPage) {
      currentpage.primary.id = page.parentPage.parentPage.id;
      currentpage.primary.title = page.parentPage.parentPage.title;
      currentpage.secondary.id = page.parentPage.id;
      currentpage.secondary.title = page.parentPage.title;
      currentpage.tertiary.id = page.id;
      currentpage.tertiary.title = page.title;
    }
  } else {
    currentpage.primary.id = page.id;
    currentpage.primary.title = page.title;
  }

  return (
    <Layout className="pages" type="page">
      <SEO seo={page.pageSEO} title={page.title} />
      {page.pageBuilder[0] && (
        <PageBuilder pagebuilder={page.pageBuilder} currentpage={currentpage} />
      )}
    </Layout>
  );
};

export default PagesTemplate;
